<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-card-title class="word-break-normal">
            Количество оформленных документов
          </v-card-title>
          <v-card-subtitle>
            Информация об отчете
          </v-card-subtitle>
          <div>
            <v-row class="mx-1">
              <v-col>
                <label>
                  Дата начала
                  <custom-date-picker v-model="from" />
                </label>
              </v-col>
              <v-col>
                <label> Дата окончания
                  <custom-date-picker v-model="to" />
                </label>
              </v-col>
            </v-row>

            <v-card-actions class="mt-10 mx-2">
              <v-btn
                class="elevation-0"
                :loading="loading"
                dark
                @click="downloadReport"
              >
                <v-icon>mdi-download</v-icon> Скачать
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import CustomDatePicker from '@/components/shared/custom-date-picker.vue';
import { downloadExcel, getFilenameFromContentDisposition} from '@/helpers/documents'
import moment from 'moment'
import axios from "axios";
import {REPORTS} from "@/http-routes";

export default {
  components:{CustomDatePicker},
  data:() =>({
    from:null,
    to:null,
    loading:false,
  }),
  methods:{
    downloadReport(){
      this.loadingStart()
      const dateFrom = moment(this.from).isValid()
        ? moment(this.from).format("DD.MM.YYYY")
        : null;
      const dateTo = moment(this.to).isValid()
        ? moment(this.to).format("DD.MM.YYYY")
        : null;
      if(!dateFrom || !dateTo){
        this.loadingEnd()
        return this.$error('Неверная дата')
      }
      axios.get(`${REPORTS}getSentDeclarationsReport?dateFrom=${dateFrom}&dateTo=${dateTo}`, {
        responseType:'blob'
      }).then(res => {
        const name = getFilenameFromContentDisposition(res)
        downloadExcel(res.data, name)
      }).catch(() => this.$error('Не удалось сохранить файл'))
        .finally(() => this.loadingEnd())
    },
    loadingStart(){
      this.loading = true
    },
    loadingEnd(){
      this.loading = false
    },
  }

};
</script>
